import type { NodeItem, INodeDataLevelConfiguration } from 'o365.modules.DataObject.extensions.NodeData.ts';
import type { ItemModel } from 'o365.modules.DataObject.Types.ts';
import type { IDataGridControlProps } from 'o365.controls.DataGrid.ts';
import type { RecordSourceFieldType } from 'o365.modules.DataObject.Types.ts';
import type { ILayoutModuleOptions } from 'o365.modules.DataObject.Layout.ts';
import type DataColumn from 'o365.controls.DataGrid.Column.ts';

import { nextTick, watch } from 'vue';
import utils from 'o365.modules.utils.js';
import { addEventListener } from 'o365.vue.composables.EventListener.ts';
import { DataGridControl } from 'o365.controls.DataGrid.ts';
import { TreeColumnHeaderFactory, ExpandableCellRenderer, GroupByLevelConfiguration } from 'o365.modules.DataObject.extensions.NodeData.ts';
import { LayoutModule } from 'o365.modules.DataObject.Layout.ts';
import Sortable from 'sortable';

declare module 'o365.controls.DataGrid.ts' {
    interface DataGridControl {
        nodeData: DataGridNodeData;
        hasNodeData: boolean;
    }
}

Object.defineProperties(DataGridControl.prototype, {
    'nodeData': {
        get() {
            if (this._nodeData == null) {
                this._nodeData = new DataGridNodeData(this);
            }
            return this._nodeData;
        }
    },
    'hasNodeData': {
        get() {
            return !!this._nodeData;
        }
    }
});


export default class DataGridNodeData {
    private _dataGridControl: DataGridControl;
    private _nodeColumnProps!: ONodeColumnProps;
    private _cancelTokens: (()=>void)[] = [];
    private _initialized = false;
    private _groupBy = false;
    private _aggregatesArray: GroupByAggregate[] = [];
    private _userAggregates: boolean = false;
    groupByContainerField?: string;

    /** Indicates if group by mode is enabled for grid */
    get groupBy() { return this._groupBy; }
    set groupBy(pValue) { this._groupBy = pValue; }

    get aggregates() { return this._aggregatesArray; }
    /** Indicates if user defined aggregates are enabled */
    get userAggregates() { return this._userAggregates; }

    get props() { return this._nodeColumnProps; }

    constructor(pDataGrid: DataGridControl) {
        this._dataGridControl = pDataGrid;
    }
    /** Setup grid for recieving node data (called by NodeColumn) */
    initialize2(pProps: ONodeColumnProps) {
        if (this._initialized) { return; }
        this._initialized = true;
        this._nodeColumnProps = pProps;
        if (this._dataGridControl.dataObject == null) { return; }
        this._dataGridControl.dataObject.nodeData.autoExpandOnFilter = !this.groupBy || this._dataGridControl.dataObject.nodeData.loadFullStructure;

        this._cancelTokens.push(watch(() => this._dataGridControl.dataObject!.nodeData.enabled, (isEnabled: boolean) => {
            this._dataGridControl.virtualScrollApi?.updateWatcher(isEnabled);
        }));
        if (this._dataGridControl.dataObject.nodeData.enabled) {
            this._dataGridControl.virtualScrollApi?.updateWatcher(true);
        }

        this._cancelTokens.push(this._dataGridControl.dataObject.nodeData.events.on('NodeAdded', (...args) => this._onNodeAdded(...args)));
        this._cancelTokens.push(this._dataGridControl.dataObject.nodeData.events.on('ConfigurationAdded', this._onConfigurationAdded.bind(this)));
        this._cancelTokens.push(this._dataGridControl.dataObject.nodeData.events.on('ExpandedToNode', (...args) => this._onNodeExpandedTo(...args)));

        if (this._groupBy) {
            this._cancelTokens.push(this._dataGridControl.dataObject?.nodeData.events.on('SetGroupBy', () => {
                this.updateNodeColumnState();
            }));
            this._dataGridControl.dataColumns.columns.filter(x => x.groupAggregate).forEach(column => {
                this.addAggregate(column.colId);
                // this._aggregatesArray.push({ name: column.field, caption: column.headerName, aggregate: column.groupAggregate as any, type: column.type });
            });
            if (this._dataGridControl.dataObject.nodeData.configurations.length > 0) {
                this._dataGridControl.dataObject.nodeData.configurations.forEach(configuration => {
                    if (configuration instanceof GroupByLevelConfiguration) {
                        configuration.updateAggregates(this._aggregatesArray);
                    }
                });
            }
            if (this._dataGridControl.dataObject.layoutManager && !pProps.disableLayouts) {
                this._dataGridControl.dataObject.layoutManager.registerModule('nodeData', LayoutNodeDataGroupByModule, {
                    baseline: LayoutNodeDataGroupByModule.getValueFromDataGridControl(this._dataGridControl)
                });
            }
        }

        this.updateNodeColumnState();
    }

    /** Setup grid for recieving node data */
    initialize(pOptions: NonNullable<IDataGridControlProps['nodeData']>) {
        if (this._initialized) { return; }
        if (this._dataGridControl.dataObject == null) { return; }
        this._initialized = true;

        const displayField = pOptions.displayField ?? 'ID';

        this._cancelTokens.push(watch(() => this._dataGridControl.dataObject!.nodeData.enabled, (isEnabled: boolean) => {
            console.log(isEnabled);
        }));


        const TreeHeaderSlot = TreeColumnHeaderFactory(this._dataGridControl, 'o365.vue.components.NodeDataLevels.vue');
        let filter: string | boolean = false;
        if (pOptions.displayField) {
            filter = 'OFilter';
        }
        const levelIndent = pOptions.indent != null
            ? parseFloat(`${pOptions.indent}`)
            : 24;
        const definition = {
            colId: 'AutoTreeGroup',
            field: displayField,
            editable: pOptions.column?.editable,
            headerName: pOptions.column?.headerName ?? 'Group',
            headerTitle: pOptions.column?.headerTitle ?? pOptions.column?.headerName ?? 'Group',
            filter: pOptions.column?.filter ?? filter,
            filterdropdown: () => '',
            width: pOptions.column?.width ?? 400,
            pinned: pOptions.column?.pinned,
            cellTitle: pOptions.column?.cellTitle,
            singleClickEdit: false,
            headerTextSlot: TreeHeaderSlot,
            cellRenderer: ExpandableCellRenderer,
            cellRendererParams: {
                handleClick: (row: NodeItem, _col: DataColumn) => {
                    if (row.expanded) {
                        row.collapse();
                    } else {
                        row.expand();
                    }
                },
                getLeftMargin: (row: NodeItem) => (row.level ?? 0) * levelIndent,
                isExpandable: (row: NodeItem) => row.hasNodes,
                isCollapsed: (row: NodeItem) => !row.expanded,
                isLoading: (row: NodeItem) => row.isLoading,
                getDisplay: (pOptions.getDisplay) ?? (row => row[displayField]),
                boldDisplay: pOptions.column?.boldDisplay ?? false,
            }
        };
        const indexToInsertAt = this._dataGridControl.dataColumns.columns.findIndex(x => !x.colId.startsWith('o365_'));
        this._dataGridControl.addColumn(definition, indexToInsertAt);

        this._cancelTokens.push(this._dataGridControl.dataObject.nodeData.events.on('NodeAdded', (...args) => this._onNodeAdded(...args)));
        this._cancelTokens.push(this._dataGridControl.dataObject.nodeData.events.on('ExpandedToNode', (...args) => this._onNodeExpandedTo(...args)));
    }

    /** Toggle disabled state of a configuration level */
    toggleLevel(pIndex: number) {
        const config = this._dataGridControl.dataObject?.nodeData.configurations?.[pIndex];
        if (config == null) { return; }
        config.disabled = !config.disabled;
        this._dataGridControl.dataObject!.nodeData.enable();
        return this._dataGridControl.dataObject!.load();
    }

    /**
     * Add DataColumn as a node configuration level based on the column options.
     * @param pId Id of the column to add
     */
    addColumnAsLevel(pId: string) {
        if (this._dataGridControl.dataObject == null) { return; }
        const column = this._dataGridControl.dataColumns.getColumn(pId);
        if (column == null) { return; }

        this._dataGridControl.dataObject.nodeData.addConfiguration({
            type: 'groupBy',
            fieldName: column.field,
            aggregates: this._aggregatesArray,
            pathField: column.groupPathField,
            pathIdReplace: column.groupPathReplacePlaceholder
        });
        this._dataGridControl.dataObject.nodeData.enable();
        this.updateNodeColumnState();
        this.saveToLayout();
        return this._dataGridControl.dataObject.load();
    }

    removeLevel(pLevel: number) {
        if (this._dataGridControl.dataObject == null) { return; }
        this._dataGridControl.dataObject.nodeData.removeConfiguration(pLevel);
        if (this._dataGridControl.dataObject.nodeData.configurations.length === 0) {
            this._dataGridControl.dataObject.nodeData.disable();
        }
        this.updateNodeColumnState();
        this.saveToLayout();
        return this._dataGridControl.dataObject.load();
    }

    togglePathGroupBy(pLevel: GroupByLevelConfiguration) {
        if (this._dataGridControl.dataObject == null) { return; }
        pLevel.pathMode = !pLevel.pathMode;
        return this._dataGridControl.dataObject.load();
    }

    /** Add DataColumn to aggregates */
    addAggregate(pId: string, pAggregate?: string) {
        if (this._dataGridControl.dataObject == null) { return; }
        const column = this._dataGridControl.dataColumns.getColumn(pId);
        if (column == null || column.unbound) { return; }
        this._aggregatesArray.push({ name: column.field, caption: column.headerName, aggregate: pAggregate as any ?? column.groupAggregate, type: column.type });
        if (this._dataGridControl.dataObject.nodeData.configurations.length > 0) {
            this._dataGridControl.dataObject.nodeData.configurations.forEach(configuration => {
                if (configuration instanceof GroupByLevelConfiguration) {
                    configuration.updateAggregates(this._aggregatesArray);
                }
            });
            this._dataGridControl.dataObject.load();
        }
        this.saveToLayout();
    }

    removeAggregate(pField: string) {
        if (this._dataGridControl.dataObject == null) { return; }
        const index = this._aggregatesArray.findIndex(x => x.name === pField);
        if (index !== -1) {
            this._aggregatesArray.splice(index, 1);
            if (this._dataGridControl.dataObject.nodeData.configurations.length > 0) {
                this._dataGridControl.dataObject.nodeData.configurations.forEach(configuration => {
                    if (configuration instanceof GroupByLevelConfiguration) {
                        configuration.updateAggregates(this._aggregatesArray);
                    }
                });
                this._dataGridControl.dataObject.load();
            }
        }
        this.saveToLayout();
    }

    updateAggregate(pAggregate: GroupByAggregate, pValue: GroupByAggregate['aggregate']) {
        pAggregate.aggregate = pValue;
        if (this._dataGridControl.dataObject == null) { return; }
        if (this._dataGridControl.dataObject.nodeData.configurations.length > 0) {
            this._dataGridControl.dataObject.nodeData.configurations.forEach(configuration => {
                if (configuration instanceof GroupByLevelConfiguration) {
                    configuration.updateAggregates(this._aggregatesArray);
                }
            });
            this._dataGridControl.dataObject.load();
        }
        this.saveToLayout();
    }

    /**
     * Save current nodeData configuration to current layout. 
     * Only available in groupBy mode 
     */
    saveToLayout() {
        return;
        if (this.groupBy && this._dataGridControl.dataObject && this._dataGridControl.dataObject.layoutManager) {
            this._dataGridControl.dataObject.layoutManager.saveLayout({
                includedModules: ['nodeData']
            });
        }
    }

    destroy() {
        this._cancelTokens.forEach(ct => ct());
        this._cancelTokens.splice(0, this._cancelTokens.length);
        delete (this._dataGridControl as any)._nodeData;
    }

    updateNodeColumnState() {
        if (this.props.onlyShowOnGroupBy) {
            const column = this._dataGridControl.dataColumns.getColumn('AutoTreeGroup');
            const initialColumn = this._dataGridControl.dataColumns.initialColumnsMap['AutoTreeGroup'];
            if (column == null) { return; }
            if (this._dataGridControl.dataObject!.nodeData.configurations.length > 0) {
                if (!column.hide) { return; } 
                column._hide = false;
                column.hideFromChooser = false;
            } else {
                if (column.hide) { return; } 
                column._hide = true;
                column.hideFromChooser = true;
            }
            if (initialColumn) {
                initialColumn.hide = column.hide;
            }
        }
    }

    shouldShowColumn() {
        return this._dataGridControl.dataObject!.nodeData.configurations.length > 0;
    }

    runAfterLayoutApplyTasks() {
        this._dataGridControl.dataObject?.nodeData.configurations.forEach(config => {
            if (config instanceof GroupByLevelConfiguration) {
                const column = this._dataGridControl.dataColumns.getColumn(config.field);
                if (column && column.groupPathField) {
                    config.updatePathSetings({
                        pathField: column.groupPathField,
                        pathFn: column.groupPathReplacePlaceholder
                    })
                }
            }
        })
    }

    private async _onNodeAdded(pNode: NodeItem) {
        if (this._dataGridControl.hasNavigation && pNode.displayIndex != null)  {
            this._dataGridControl.navigation.focusFirstEditableCell(pNode.displayIndex, this._dataGridControl.navigation.containerEnum.Main);
            this._dataGridControl.navigation.enterEditMode();
        }
    }

    private async _onNodeExpandedTo(pNode: NodeItem) {
        nextTick().then(async () => {
            if (pNode && pNode.displayIndex != null) {
                this._attemptGridScroll(pNode.displayIndex);
                if (pNode.isLoading) {
                    await pNode.loadingPromise;
                }
                if (pNode.index != null) {
                    this._dataGridControl.dataObject?.setCurrentIndex(pNode.index, true);
                }
            }
        });
    }

    private _attemptGridScroll(pIndex: number) {
        if (this._dataGridControl.getVerticalScrollViewport == null
            || this._dataGridControl.dataObject == null) {
            return;
        }
        const viewport: HTMLElement | undefined = this._dataGridControl.getVerticalScrollViewport();
        if (viewport == null) { return; }


        viewport.scrollTop = pIndex * this._dataGridControl.props.rowHeight;
    }

    private _onConfigurationAdded(pConfig: INodeDataLevelConfiguration) {
        if (pConfig instanceof GroupByLevelConfiguration) {
            if (pConfig.formatDisplayValue == null && pConfig.field) {
                const col = this._dataGridControl.dataColumns.columns.find(col => col.field == pConfig.field && col.format);
                if (col) {
                    pConfig.formatDisplayValue = (pValue: any) => utils.format(pValue, col);
                }
            }
        }
    }

}

/** 
 * Helper control for group by containers in grids
 */
export class GroupByContainerControl {
    private _dataGridControl: DataGridControl;
    private _groupByContainerEl?: HTMLElement;
    private _groupByContainerCleanupTokens: (() => void)[] = [];
    private _placeholder?: {
        isPlaceholder: true,
        key: string,
        level: number,
        name: string,
        duplicate: boolean
    };
    private _placeholderPosition?: number;

    get placeholderPosition() { return this._placeholderPosition; }
    get placeholder() { return this._placeholder; }
    
    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    initializeGroupByContainer(pElement: HTMLHtmlElement) {
        if (pElement == null) { return; }
        this._groupByContainerEl = pElement;
        this._groupByContainerCleanupTokens.push(addEventListener(this._groupByContainerEl, 'drop', this._groupByContainerOnDrop.bind(this)));
        this._groupByContainerCleanupTokens.push(addEventListener(this._groupByContainerEl, 'dragover', this._groupByContainerOnDragOver.bind(this)));
        this._groupByContainerCleanupTokens.push(addEventListener(this._groupByContainerEl, 'dragleave', this._groupByContainerOnDragLeave.bind(this)));
        const groupBySortable = new Sortable(pElement, {
            sort: true,
            animation: 150,
            filter: '.o365-group-by-hierarchy',
            ghostClass: 'o365-group-by-ghost',
            handle: '.o365-group-by-handle',
            draggable: '.o365-group-by-item',
            onUpdate: (pEvent: any) => {
                const fromLevel = pEvent.oldIndex as number;
                const toLevel = pEvent.newIndex as number;
                this._dataGridControl.dataObject?.nodeData.moveConfiguration(fromLevel, toLevel);
                this._dataGridControl.dataObject?.load();
            }
        });
        this._groupByContainerCleanupTokens.push(() => groupBySortable.destroy());
    }
    initializeAggregatesContainer(pElement: HTMLHtmlElement) {
        if (pElement == null) { return; }
        this._groupByContainerCleanupTokens.push(addEventListener(pElement, 'drop', this._aggregatesContainerOnDrop.bind(this)));
        this._groupByContainerCleanupTokens.push(addEventListener(pElement, 'dragover', this._aggregatesContainerOnDragOver.bind(this)));
    }

    clearContainers() {
        this._groupByContainerCleanupTokens.splice(0, this._groupByContainerCleanupTokens.length).forEach(ct => ct());
    }

    private _groupByContainerOnDrop(pEvent: DragEvent) {
        const isGroupItem = pEvent.dataTransfer?.types.includes('o365-nt/group-by-column');
        if (!isGroupItem || pEvent.dataTransfer == null) { return; }
        this._placeholder = undefined;
        this._placeholderPosition = undefined;
        const data = JSON.parse(pEvent.dataTransfer.getData('o365-nt/group-by-column') ?? '') as { colId: string };
        if (data.colId == null) { return; }
        pEvent.preventDefault();
        this._dataGridControl.nodeData.addColumnAsLevel(data.colId);
    }

    private _groupByContainerOnDragLeave(pEvent: DragEvent) {
        const closest = (pEvent.relatedTarget as HTMLElement)?.closest('.o365-group-by-container');
        if (closest == null) {
            this._placeholder = undefined;
            this._placeholderPosition = undefined;
        }
    }
    private _groupByContainerOnDragOver(pEvent: DragEvent) {
        const isGroupItem = pEvent.dataTransfer?.types.includes('o365-nt/group-by-column');
        if (!isGroupItem || pEvent.dataTransfer == null) { return; }
        pEvent.dataTransfer.dropEffect = 'link';
        if (this._placeholder == null) {
            let placeholderCol: string | null = null
            if (pEvent.dataTransfer.types.includes('o365-nt/column-order')) {
                // placeholderCol = this._dataGridControl.columnMove?.movedColumn.field;
                placeholderCol = this._dataGridControl.columnMove?.movedInstance?.colId!;
            } else {
                placeholderCol = this._dataGridControl.nodeData.groupByContainerField ?? null;
            }
            const column = this._dataGridControl.dataColumns.getColumn(placeholderCol);
            if (column == null) { return; }

            this._placeholder = {
                isPlaceholder: true,
                key: 'placeholder-item',
                level: -1,
                name: column.headerName,
                duplicate: this._dataGridControl.dataObject!.nodeData.configurations.some(config => {
                    if (config instanceof GroupByLevelConfiguration) {
                        return config?.field === column?.field;
                    }
                    return false;
                })
            };
            this._placeholderPosition = this._dataGridControl.dataObject!.nodeData.configurations.at(-1)?.type === 'hierarchy'
                ? this._dataGridControl.dataObject!.nodeData.configurations.length -1
                : this._dataGridControl.dataObject!.nodeData.configurations.length;
        }
        pEvent.preventDefault();
    }
    private _aggregatesContainerOnDrop(pEvent: DragEvent) {
        const isGroupItem = pEvent.dataTransfer?.types.includes('o365-nt/group-by-column');
        if (!isGroupItem || pEvent.dataTransfer == null) { return; }
        const data = JSON.parse(pEvent.dataTransfer.getData('o365-nt/group-by-column') ?? '') as { colId: string };
        if (data.colId == null) { return; }
        pEvent.preventDefault();
        this._dataGridControl.nodeData.addAggregate(data.colId);
    }
    private _aggregatesContainerOnDragOver(pEvent: DragEvent) {
        const isGroupItem = pEvent.dataTransfer?.types.includes('o365-nt/group-by-column');
        if (!isGroupItem || pEvent.dataTransfer == null) { return; }
        pEvent.dataTransfer.dropEffect = 'link';
        pEvent.preventDefault();
    }
}

class LayoutNodeDataGroupByModule<IT extends ItemModel = ItemModel> extends LayoutModule<IT, GroupByLayoutValue>{
    // TODO: Move to cached property
    static getValueFromDataGridControl(pDataGridControl?: DataGridControl) {
        if (pDataGridControl == null || pDataGridControl.dataObject == null || !pDataGridControl.dataObject.hasNodeData || !pDataGridControl.hasNodeData) {
            return undefined;
        }
        const groupBy = pDataGridControl.dataObject.nodeData.configurations.reduce((result, item) => {
            if (item instanceof GroupByLevelConfiguration) {
                if (item.field) {
                    result.push([item.field]);
                } else if (item.fields) {
                    result.push([...item.fields]);
                }
            }
            return result;
        }, [] as string[][]);
        if (groupBy.length > 0) {
            const aggregates = pDataGridControl.nodeData.aggregates.map(item => ({
                name: item.name,
                aggregate: item.aggregate
            }));
            return {
                aggregates: aggregates.length > 0 ? aggregates : undefined,
                groupBy: groupBy
            }
        } else {
            return undefined;
        }
    }

    constructor(pOptions: ILayoutModuleOptions<IT>, pInitialValue: GroupByLayoutValue, pModuleOptions: {
        baseline?: GroupByLayoutValue
    }) {
        super('nodeData', pOptions);
        if (pModuleOptions.baseline) {
            this._baseline = pModuleOptions.baseline;
        }

        if (pInitialValue) {
            this.apply(pInitialValue);
            const dataObject = this.getDataObject();
            if (dataObject.state.isLoaded || dataObject.state.isLoading) {
                if (dataObject.state.loadingPromise) {
                    dataObject.state.loadingPromise.then(() => {
                        dataObject.load();
                    });
                } else {
                    dataObject.load();
                }
            }
        }
    }

    apply(pValue?: GroupByLayoutValue, pSkipValueSet = false) {
        const dataObject = this.getDataObject();
        if (!dataObject.hasNodeData) { return; }
        const dataGridControl = this.getSharedControl('dataGridControl');
        if (dataGridControl == null) { return; }
        dataObject.nodeData.setGroupBy(pValue?.groupBy ?? []);
        dataGridControl.nodeData.updateNodeColumnState();
        dataGridControl.nodeData.runAfterLayoutApplyTasks();
        if (!pSkipValueSet) {
            this._value = pValue;
        }
    }
    
    getValue() {
        return this._value;
    }

    getValueForSave() {
        const dataGridControl = this.getSharedControl('dataGridControl');
        return LayoutNodeDataGroupByModule.getValueFromDataGridControl(dataGridControl);
    }

    hasChanges() {
        const newValue = this.getValueForSave();
        if (newValue && this._value) {
            return JSON.stringify(newValue) !== JSON.stringify(this._value);
        } else {
            return newValue != null || this._value != null;
        }
    }

    mergeValues(pBase: GroupByLayoutValue, pOverrides: GroupByLayoutValue) {
        return pOverrides ?? pBase;
    }

    reset() {
        if (this._baseline && JSON.stringify(this._value) !== JSON.stringify(this._baseline)) {
            this.apply(this._baseline);
        }
        this._value = undefined;
    }

    shouldLoadDataObject() { return true; }
} 

type GroupByAggregate = {
    name: string;
    aggregate: NonNullable<RecordSourceFieldType['aggregate']>,
    type: string;
    caption: string;
};

type GroupByLayoutValue = {
    aggregates?: {
        name: string,
        aggregate: NonNullable<RecordSourceFieldType['aggregate']>,
    }[],
    groupBy?: string[][]
}

type ONodeColumnProps = {
    field?: string;
    editable?: boolean | ((node: NodeItem) => boolean);
    headerName?: string;
    headerTitle?: string;
    filter?: any;
    width?: number | string;
    minWidth?: string | number;
    flexWidth?: string | number;
    pinned?: 'left' | 'right';
    cellTitle?: string | ((node: NodeItem) => string);
    indent?: number | string;
    getDisplay?: (node: NodeItem) => any;
    boldDisplay?: boolean;

    cellStyle?: any
    classFn?: (node: NodeItem) => any;
    getCopyValue?: (node: NodeItem) => any;
    filterField?: string;
    sortField?: string;
    sortable?: boolean;
    disableMenu?: boolean;
    disableResize?: boolean;
    cellRendererParams?: any;
    cellrendererparams?: any;
    /** When true the default renderer will display column captions for group by configurations  */
    showPrefix?: boolean;
    /** When true the default rednerer will not display detail counts */
    hideCount?: boolean;
    /** Enable group by mode in grid */
    groupBy?: boolean;
    /** Enable client side node structure */
    loadFullStructure?: boolean;
    /** Enable user defined aggregates */
    userAggregates?: boolean;
    /** When enabled this column will only be visible in grid when there is active group by */
    onlyShowOnGroupBy?: boolean;
}